const translation = {
  selected: 'valgt',
  copyToClipboardButton: 'Kopieret til udklipsholder',
  enableLanguageSelector: 'Oversæt til et andet sprog',
  languageSelector: {
    languages: 'Sprog',
    selectLanguage: 'Vælg sprog',
  },
  signIn: 'Sign In',
  signOut: 'Sign Out',
  signInPrompt: 'You are not signed in',
  save: 'Gem',
  cancel: 'Annuller',
  close: 'Luk',
  edit: 'Rediger',
  cv: {
    downloadCV: 'Download CV',
    filename: 'CV_references_{{id}}.docx',
  },
  download: 'Download',
  downloadStarted: 'Download påbegyndt',
  includeSeveraAndMajakkaIDs: 'Inkludér Severa og Majakka IDs',
  localizationLanguages: {
    fi: "Finsk",
    en: "Engelsk",
    sv: "Svensk",
    da: "Dansk"
  },
  errors: {
    download: 'Fejl ved download af fil: {{error}}',
    fetchPersonCards: 'Fejl ved hentning af personer: {{error}}',
    fetchProject: 'Fejl ved hentning af projekt {{error}}',
    fetchProjectSummaries: 'Fejl ved hentning af projektoversigter: {{error}}',
    fetchReference: 'Fejl ved hentning af en reference: {{error}}',
    saveProject: 'Error saving project: {{error}}',
    saveReference: 'Fejl ved gemning af reference: {{error}}',
  },
  tooltips: {
    projects: {
      referenceStatus: 'Shows in which languages the ended project has all approved references',
      scope: 'Scope of project in euros',
    },
  },
  nav: {
    cv: 'Medarbejder CV',
    heading: 'NHG referenceværktøj',
    references: 'Eksport og redigering af referencer',
    refEdit: 'Reference redigering',
  },
  projects: {
    downloadForPowerpoint: 'Download til Powerpoint',
    downloadForWord: 'Download til Word',
    ended: 'Afsluttet',
    label: 'Projekter',
    name: 'Navn',
    started: 'Startet',
    scope: '€',
    edit: {
      saveSuccess: 'Project details successfully changed.'
    },
    search: {
      customer: 'Kunde',
      fullTextSearch: 'Fuldtekstsøgning (eller Severa #)',
      heading: 'Søg efter projekter',
      projectOwner: 'Projektleder',
      salesperson: 'Select salesperson',
      businessUnit: 'Select business unit',
      projectWorker: 'Vælg projektmedarbejder',
      scopeInManDays: 'Omfang (mandage)',
      timeframeYearsAgo: 'Tidsramme (år siden)',
    },
  },
  references: {
    edit: {
      additionalInformation: {
        label: 'NHG-holdet',
        placeholder: "NHG's kontaktpersoner / involverede personer",
      },
      customerContacts: {
        label: 'Kunde kontakter',
        placeholder: 'Navn og e-mail',
      },
      cvReference: {
        label: 'CV Reference',
        placeholder: "Kort beskrivelse til CV'er og tilbud, undgå punktopstilling. Beskriv tydeligt projektet, målene og resultaterne uden at introducere nye emner, som ikke er nævnt i den lange reference.",
      },
      discardChanges: {
        description: 'Vil du forkaste ændringerne?',
        discard: 'Forkast ændringer',
      },
      duration: {
        label: 'Varighed',
        placeholder: '',
      },
      execution: {
        label: 'Udførelse',
        placeholder: 'Beskriv projektets struktur - faser og delprojekter, f.eks. "Analyse, Udvikling, Implementering, Evaluering."',
      },
      introduction: {
        label: 'Introduktion',
        placeholder: 'Kort projektintroduktion, f.eks. "SAKARI: Fælles projekt finansieret af Social- og Sundhedsministeriet med det formål at forbedre ældreplejetjenester."',
      },
      methods: {
        label: 'Metoder',
        placeholder: 'Liste over ALLE anvendte metoder. Kan også være en del af Udførelse.',
      },
      objectives: {
        label: 'Mål',
        placeholder: 'Beskriv projektets formål konkret, f.eks. "Udvikle og implementere en tilpasset, kundeorienteret hjemmehjælpemodel."',
      },
      results: {
        label: 'Resultater',
        placeholder: 'Beskriv konkrete resultater, handlinger og virkninger, eventuelt opdelt i faser, f.eks. "Byen Porvoo øgede den effektive arbejdstid med 9 procentpoint og reducerede vikarbehovet med 37 procent. Arbejdsbog oprettet til implementeringsmodellen tilgængelig på www.xxx.fi."',
      },
      saveSuccess: 'Reference gemt',
      translationSuccess: "Oversættelser oprettet succesfuldt",
      scope: {
        label: 'Scope',
        placeholder: '',
      },
      unsavedChanges: {
        continueEditing: 'Fortsæt redigering',
        description:
          'Vil du gemme dine ændringer, inden du forlader redigeringsværktøjet?',
        descriptionLanguageChange: 'Vil du gerne gemme dine ændringer?',
        discardChanges: 'Kassér ændringer og afslut',
        discardChangesAndChangeLanguage: 'Forkast ændringer',
        saveAndExit: 'Gem og afslut',
        title: 'Du har ikke-gemte ændringer',
        save: 'Gem ændringer',
      },
    },
    peopleSelector: {
      selectEmployee: 'Vælg medarbejder',
    },
    icons: {
      hasReferences: 'Projektet har uautoriserede referencer',
      hasCVReference: 'Projektet har ikke en CV-reference',
    },
    infoPage: {
      referenceExportAndEditGuide: {
        guide1: 'Når et projekt åbnes i Severa, er det også tilgængeligt i Reference-værktøjet efter næste opdateringsrunde. Opdateringer køres hver time.',
        guide2: 'Åbne projekter har endnu ikke en slutdato, omfang eller € i værktøjet, men andre grundlæggende oplysninger bør være på plads, hvis de også er i Severa. Referencer kan og opfordres til at blive skrevet til åbne projekter.',
        guide3: 'Når projektet lukkes, vises også slutdato, omfang og € i Reference-værktøjet.',
        guide4: 'Landsflag ikoner angiver, hvilke sprogversioner er blevet godkendt af nogen (normalt projektets ejer, der skriver referencen). Ikonet på søgesiden bliver ”farvet”, når alle obligatoriske felter for sprogversionen er godkendt og gemt.',
        guide5: 'Hvis du ønsker at redigere en allerede godkendt tekst i værktøjet, fjern markeringen i ”godkendt” afkrydsningsfeltet, rediger, godkend og gem den nye version.',
        guide6: 'Navigation mellem sprogversioner sker i redigeringsvinduet.'
      },
      employeeCvGuide: {
        guide1: 'Hver medarbejder kan downloade CV-referencer for de projekter, de har arbejdet på. Denne funktion downloader alle kundeprojekter, hvor medarbejderen har registreret timer.',
        guide2: 'Hvis en CV-reference mangler fra et allerede afsluttet projekt, skal du kontrollere med projektlederen, at det er skrevet i første omgang.',
        guide3: 'Denne liste er en udkastversion, som en medarbejder kan bruge som tillæg til sit CV.'
      }
    }
  }
};

export default translation;
