import React, { useMemo, useState } from 'react';
import { createRoot } from 'react-dom/client';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import { CurriculumVitae } from './routes/cv';
import { References } from './routes/references';
import { Route } from './types';
import 'dotenv/config';

import './i18n';

import { useTranslation } from 'react-i18next';
import { Navigation } from './components/Navigation';
import { Info } from './routes/info';
import { AlertColor, ThemeProvider } from '@mui/material';
import { theme } from './material-ui/theme';
import { AlertContext } from './context/AlertContext';
import { AlertSnackbar } from './components/AlertSnackbar';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { EventType, AuthenticationResult } from '@azure/msal-browser';

const getRouter = (routes: Route[]) =>
  createBrowserRouter([
    {
      path: '/',
      element: <Navigation routes={routes} />,
      children: [
        ...routes,
        { path: 'references/:projectId?', element: <References /> },
        { path: 'references/info?', element: <Info /> },
        { path: '*', element: <Navigate to="/references" /> },
        { path: '/', element: <Navigate to="/references" /> },
      ],
    },
  ]);

export const msalInstance = new PublicClientApplication({
  auth: {
    clientId: 'a608d2e8-592d-4e58-87fe-ca186d02fd18',
    authority: 'https://login.microsoftonline.com/aad52afe-e8c8-4099-989c-fdf985e09472',
    redirectUri: '/',
  },
  system: {
    loggerOptions: {
      loggerCallback: (Level, message, _containsPII) => {
        console.log(message)
      },
      logLevel: LogLevel.Info,
    }
  }
})
msalInstance.initialize()

const App: React.FC = () => {
  const { t } = useTranslation();

  const [message, setMessage] = useState<string>();
  const [severity, setSeverity] = useState<AlertColor>();

  const updateMessage = (message?: string, severity?: AlertColor) => {
    setMessage(message);
    setSeverity(severity);
  };

  msalInstance.addEventCallback(event => {
    if (event.eventType === EventType.LOGIN_SUCCESS) {
      console.log(event)
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);

    }
  })

  const routes: Route[] = useMemo(
    () => [
      {
        name: t('nav.cv'),
        path: 'cv',
        element: <CurriculumVitae />,
      },
      {
        name: t('nav.references'),
        path: 'references',
        element: <References />,
      },
      {
        name: "Info",
        path: 'info',
        element: <Info />,
      }
    ],
    [t],
  );

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <MsalProvider instance={msalInstance}>
          <Provider store={store}>
            <AlertContext.Provider
              value={{ message, setMessage: updateMessage, severity }}
            >
              <AlertSnackbar />

              <RouterProvider router={getRouter(routes)} />
            </AlertContext.Provider>
          </Provider>
        </MsalProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
};

console.log("v2") // this exists so we can ensure that correct version has been deployed

const domNode = document.getElementById('root');

if (domNode) {
  const root = createRoot(domNode);

  root.render(<App />);
}
