import React, { useContext, useEffect } from 'react';
import { Table, TableBody, TableCell, TableRow, Typography, Stack, Select, MenuItem } from '@mui/material';
import { CopyToClipboardButton } from './CopyToClipboardButton';
import { useSaveProjectMutation } from '../../store/services/api';
import { AlertContext } from '../../context/AlertContext';
import { useTranslation } from 'react-i18next';
import { Confidentiality, Project } from '../../api';

export const ProjectInformationTable = ({ project }) => {

  const projectOwnerName = project.ownerName || '-'
  const salespersonName = project.salespersonName || '-'
  const customer = project.customerName || '-'
  const businessUnit = project.businessUnit || '-'
  const scopeInManDays = project.scopeManDaysWorked ? project.scopeManDaysWorked.toFixed(1) : "-"
  const scopeInEuros = project.scope ? `${Math.round(project.scope)} €` : "-"

  const [confidentiality, setConfidentiality] = React.useState(project.confidentiality);
  const [
    saveProject,
    { isLoading: _saveProjectIsLoading, error: saveProjectError },
  ] = useSaveProjectMutation();

  useEffect(() => {
    if (saveProjectError) {
      setMessage(t('errors.saveProject', { error: saveProjectError }), 'error');
    }
  }, [saveProjectError]);
  const { setMessage } = useContext(AlertContext);
  const { t } = useTranslation();

  const onChangeConfidentiality = (event) => {
    setConfidentiality(event.target.value);
    handleSaveConfidentiality(event.target.value as Confidentiality)
  };

  const handleSaveConfidentiality = (data: Confidentiality) => {
    const saveProj = async () => {
      if (!(project as Project)) {
        throw new Error("no project selected")
      }
      if (!data) {
        throw new Error("invalid confidentiality string")
      }
      try {
        const proj: Project = {
          id: project.id,
          name: project.name,
          customerName: project.customerName,
          owner: project.owner,
          businessUnit: project.businessUnit,
          confidentiality: data,
          started: project.started,
          referenceStatus: project.referenceStatus,
        }
        await saveProject({ project: proj });
        setMessage(t('projects.edit.saveSuccess'));
      } catch (e) {
        setMessage(t('errors.saveProject', { error: e.message }), 'error');
      }
    };
    saveProj();
  };

  // TODO: adjust row gap so that the confidentiality dropdown doesn't look out of place
  return (
    <Table size="small" aria-label="a dense table" >
      <TableBody sx={{
        '& .MuiTableCell-root': {
          height: '30px',
        },
      }}>
        <TableRow key={project.id}>
          <TableCellWithoutBorders>
            <Typography variant="body2" sx={{ color: 'gray' }}>ID</Typography>
          </TableCellWithoutBorders>
          <TableCellWithoutBorders>
            <Stack direction="row">
              <Typography variant="body2" sx={{ color: 'gray' }}>{project.id}</Typography>
              <CopyToClipboardButton projectId={project.id} />
            </Stack>
          </TableCellWithoutBorders>
        </TableRow>
        <TableRow key={project.severaNumber}>
          <TableCellWithoutBorders>
            <Typography variant="body2" sx={{ color: 'gray' }}>Severa number</Typography>
          </TableCellWithoutBorders>
          <TableCellWithoutBorders>
            <Stack direction="row">
              <Typography variant="body2" sx={{ color: 'gray' }}>{project.severaNumber || '-'}</Typography>
              {project.severaNumber && <CopyToClipboardButton projectId={project.severaNumber} />}
            </Stack>
          </TableCellWithoutBorders>
        </TableRow>
        <TableRow>
          <TableCellWithoutBorders>
            <Typography variant="body2" sx={{ color: 'gray' }}>Customer</Typography>
          </TableCellWithoutBorders>
          <TableCellWithoutBorders>
            <Typography variant="body2" sx={{ color: 'gray' }}>{customer}</Typography>
          </TableCellWithoutBorders>
        </TableRow>
        <TableRow>
          <TableCellWithoutBorders>
            <Typography variant="body2" sx={{ color: 'gray' }}>Project owner</Typography>
          </TableCellWithoutBorders>
          <TableCellWithoutBorders>
            <Typography variant="body2" sx={{ color: 'gray' }}>{projectOwnerName}</Typography>
          </TableCellWithoutBorders>
        </TableRow>
        <TableRow>
          <TableCellWithoutBorders>
            <Typography variant="body2" sx={{ color: 'gray' }}>Salesperson</Typography>
          </TableCellWithoutBorders>
          <TableCellWithoutBorders>
            <Typography variant="body2" sx={{ color: 'gray' }}>{salespersonName}</Typography>
          </TableCellWithoutBorders>
        </TableRow>
        <TableRow>
          <TableCellWithoutBorders>
            <Typography variant="body2" sx={{ color: 'gray' }}>Business unit</Typography>
          </TableCellWithoutBorders>
          <TableCellWithoutBorders>
            <Typography variant="body2" sx={{ color: 'gray' }}>{businessUnit}</Typography>
          </TableCellWithoutBorders>
        </TableRow>
        <TableRow>
          <TableCellWithoutBorders>
            <Typography variant="body2" sx={{ color: 'gray' }}>Scope (man days)</Typography>
          </TableCellWithoutBorders>
          <TableCellWithoutBorders>
            <Typography variant="body2" sx={{ color: 'gray' }}>{scopeInManDays}</Typography>
          </TableCellWithoutBorders>
        </TableRow>
        <TableRow>
          <TableCellWithoutBorders>
            <Typography variant="body2" sx={{ color: 'gray' }}>Scope in €</Typography>
          </TableCellWithoutBorders>
          <TableCellWithoutBorders>
            <Typography variant="body2" sx={{ color: 'gray' }}>{scopeInEuros}</Typography>
          </TableCellWithoutBorders>
        </TableRow>
        <TableRow>
          <TableCellWithoutBorders>
            <Typography variant="body2" sx={{ color: 'gray' }}>Confidentiality</Typography>
          </TableCellWithoutBorders>
          <TableCellWithoutBorders >
            <Select value={confidentiality} onChange={onChangeConfidentiality} autoWidth={false}
              sx={{
                minWidth: 150,
                maxHeight: 30,
                fontSize: 'body2.fontSize',
                '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                  p: '10px',
                },
              }}>
              <MenuItem value={'Public'} sx={{ fontSize: 'body2.fontSize' }}>Public</MenuItem>
              <MenuItem value={'Restricted'} sx={{ fontSize: 'body2.fontSize' }}>Restricted</MenuItem>
              <MenuItem value={'Confidential'} sx={{ fontSize: 'body2.fontSize' }}>Confidential</MenuItem>
            </Select>
          </TableCellWithoutBorders>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const TableCellWithoutBorders = ({ children }) => (
  <TableCell sx={{ border: 'none', padding: 0, paddingLeft: 3, minWidth: '120px' }}>
    {children}
  </TableCell>
);