const translation = {
  selected: 'valittu',
  copyToClipboardButton: 'Kopioitu leikepöydälle',
  enableLanguageSelector: 'Käännä toiselle kielelle',
  languageSelector: {
    languages: 'Kieli',
    selectLanguage: 'Valitse kieli',
  },
  signIn: 'Kirjaudu sisään',
  signOut: 'Kirjaudu ulos',
  signInPrompt: 'Et ole kirjautunut sisään',
  save: 'Tallenna',
  cancel: 'Peruuta',
  close: 'Sulje',
  edit: 'Muokkaa',
  cv: {
    downloadCV: 'Lataa CV',
    filename: 'CV_referenssit_{{id}}.docx',
  },
  download: 'Lataa',
  downloadStarted: 'Lataus käynnistetty',
  includeSeveraAndMajakkaIDs: 'Sisällytä Severa- ja Majakka-tunnukset',
  localizationLanguages: {
    fi: 'Suomi',
    en: 'Englanti',
    sv: 'Ruotsi',
    da: 'Tanska',
  },
  errors: {
    download: 'Virhe ladattaessa tiedostoa: {{error}}',
    fetchPersonCards: 'Virhe noudettaessa henkilöitä: {{error}}',
    fetchProject: 'Virhe noudettaessa projektia {{error}}',
    fetchProjectSummaries: 'Virhe noudettaessa projektin yhteenvetoja: {{error}}',
    fetchReference: 'Virhe noudettaessa viitettä: {{error}}',
    saveProject: 'Virhe tallennettaessa projektia: {{error}}',
    saveReference: 'Virhe tallennettaessa viitettä: {{error}}',
  },
  tooltips: {
    projects: {
      referenceStatus: 'Missä kielissä päättyneellä projektilla on kaikki viitteet hyväksyttyinä',
      scope: 'Projektin laajuus euroina',
    },
  },
  nav: {
    cv: 'Työntekijän CV',
    heading: 'NHG Viitetyökalu',
    references: 'Viitteen lataus ja muokkaus',
    refEdit: 'Viitteen muokkaus',
  },
  projects: {
    downloadForPowerpoint: 'Lataa PowerPoint tiedostona',
    downloadForWord: 'Lataa Word tiedostona',
    ended: 'Päättyi',
    label: 'Projektit',
    name: 'Nimi',
    started: 'Alkanut',
    scope: '€',
    edit: {
      saveSuccess: 'Projektin yksityiskohtien muutos tallennettu.'
    },
    search: {
      customer: 'Asiakas',
      fullTextSearch: 'Tekstihaku (tai Severa #)',
      heading: 'Etsi projekteja',
      projectOwner: 'Projektipäällikkö',
      salesperson: 'Valitse myyjä',
      businessUnit: 'Valitse liiketoimintayksikkö',
      projectWorker: 'Valitse projektipäällikkö',
      scopeInManDays: 'Laajuus (henkilöpäivinä)',
      timeframeYearsAgo: 'Aikajakso (vuosia sitten)',
    },
  },
  references: {
    edit: {
      additionalInformation: {
        label: 'NHG-tiimi',
        placeholder: 'NHG:n yhteyshenkilöt / osalliset',
      },
      customerContacts: {
        label: 'Asiakaskontaktit',
        placeholder: 'Nimi ja sähköposti',
      },
      cvReference: {
        label: 'CV Viite',
        placeholder: 'Ytimekäs kuvaus ansioluetteloista ja tarjouksista, vältä luettelopisteitä. Kuvaile selvästi projektia, tavoitteita ja tuloksia aiheuttamatta uusia ongelmia, jotka eivät ole pitkässä viitteessä.',
      },
      discardChanges: {
        description: 'Haluatko hylätä muutokset?',
        discard: 'Hylkää muutokset',
      },
      duration: {
        label: 'Kesto',
        placeholder: '',
      },
      execution: {
        label: 'Toteutus',
        placeholder: 'Kuvaile projektin rakennetta - vaiheet ja aliprojektit, esim. "Analyysi, Kehitys, Toteutus, Arviointi."',
      },
      introduction: {
        label: 'Johdanto',
        placeholder: 'Lyhyt projektin esittely, esim. "SAKARI: Yhteisprojekti, joka rahoitetaan sosiaali- ja terveysministeriöltä, vanhusten kotihoitopalvelujen parantamiseksi."',
      },
      methods: {
        label: 'Menetelmät',
        placeholder: 'Luettele KAIKKI käytetyt menetelmät. Menetelmät voidaan luetella myös osana Toteutusta.',
      },
      objectives: {
        label: 'Tavoitteet',
        placeholder: 'Kuvaile projektin tavoitteet niin konkreettisesti kuin mahdollista, esim. "Projektin tavoitteena oli kehittää ja toteuttaa kotihoitopalveluiden toimintamalli, joka mahdollistaa resurssien joustavan, asiakaslähtöisen ja tehokkaan käytön."',
      },
      results: {
        label: 'Tulokset',
        placeholder: 'Kuvaile konkreettisia tuloksia, toimenpiteitä ja vaikutuksia, mahdollisesti jaettuna vaiheisiin, esim. "Porvoon kaupunki lisäsi tehokasta työaikaa 9 prosenttiyksikköä ja vähensi sijaiskäyttöä 37 prosenttia. Työkirja luotu mallin käyttöönotolle ja on saatavilla osoitteessa www.xxx.fi."',
      },
      saveSuccess: 'Viite tallennettu',
      translationSuccess: "Käännökset luotiin onnistuneesti",
      scope: {
        label: 'Laajuus',
        placeholder: '',
      },
      unsavedChanges: {
        continueEditing: 'Jatka muokkaamista',
        description: 'Haluatko tallentaa muutokset ennen poistumista?',
        descriptionLanguageChange: 'Haluatko tallentaa tekemäsi muutokset?',
        discardChanges: 'Hylkää muutokset ja poistu',
        discardChangesAndChangeLanguage: 'Hylkää muutokset',
        saveAndExit: 'Tallenna ja poistu',
        title: 'Sinulla on tallentamattomia muutoksia',
        save: 'Tallenna muutokset',
      },
    },
  },
  peopleSelector: {
    selectEmployee: 'Valitse työntekijä',
  },
  icons: {
    hasReferences: 'Projektilla on hyväksymättömiä referenssejä',
    hasCVReference: 'Projektilla ei ole CV-referenssiä',
  },
  infoPage: {
    referenceExportAndEditGuide: {
      guide1: 'Kun projekti avataan Severassa, se on käytettävissä myös Reference-työkalussa seuraavan päivityskierroksen jälkeen. Päivitykset suoritetaan tunnin välein.',
      guide2: 'Avoimilla projekteilla ei vielä ole päättymispäivää, laajuutta tai € työkalussa, mutta muut perustiedot pitäisi olla paikoillaan, jos ne ovat myös Severassa. Referenssit voidaan ja suositellaan kirjoitettaviksi myös avoimille projekteille.',
      guide3: 'Kun projekti suljetaan, myös päättymispäivä, laajuus ja € ilmestyvät Reference-työkaluun.',
      guide4: 'Maan lippukuvakkeet osoittavat, mitkä kieliversiot on hyväksytty jonkun toimesta (yleensä projektin omistajan, joka kirjoittaa referenssin). Kuvake hakusivulla muuttuu ”värilliseksi” vasta, kun kaikki pakolliset kentät kieliversiolle on hyväksytty ja tallennettu.',
      guide5: 'Jos haluat muokata jo hyväksyttyä tekstiä työkalussa, poista ”hyväksytty” -valintaruutu, muokkaa, hyväksy ja tallenna uusi versio.',
      guide6: 'Siirtyminen kieliversioiden välillä tapahtuu muokkausikkunassa.'
    },
    employeeCvGuide: {
      guide1: 'Jokainen työntekijä voi ladata työhistoriansa projektireferenssit, joissa hän on työskennellyt. Tämä toiminto lataa kaikki asiakasprojektit, joissa työntekijä on kirjannut tunteja.',
      guide2: 'Jos CV-referenssi puuttuu jo suoritetusta projektista, tarkista projektipäälliköltä, että se on kirjoitettu alun perin.',
      guide3: 'Tämä luettelo on luonnosversio, jota työntekijä voi käyttää oman CV:n lisäyksenä.'
    }
  }
};

export default translation;
