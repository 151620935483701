import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Circle } from '@mui/icons-material';

interface BulletPointProps {
    key: number;
    text: string;
};

const BulletPoint: React.FC<BulletPointProps> = ({key, text}) => {
    return (
        <ListItem key={key}>
            <ListItemIcon>
                <Circle style={{fontSize: 8}}/>
            </ListItemIcon>
                <ListItemText primary={text}/>
        </ListItem>
    );
};

export const Info: React.FC = () => {
    const { t } = useTranslation();

    const referenceExportAndEditGuide = [
        t('infoPage.referenceExportAndEditGuide.guide1'),
        t('infoPage.referenceExportAndEditGuide.guide2'),
        t('infoPage.referenceExportAndEditGuide.guide3'),
        t('infoPage.referenceExportAndEditGuide.guide4'),
        t('infoPage.referenceExportAndEditGuide.guide5'),
    ];

    const employeeCvGuide = [
        t('infoPage.employeeCvGuide.guide1'),
        t('infoPage.employeeCvGuide.guide2'),
        t('infoPage.employeeCvGuide.guide3'),
    ];

    return (
        <Box 
            display="flex" 
            flexDirection="column" 
            gap={3} 
            justifyContent="center" 
            alignItems="center"
        >
            <Typography variant="h6">{t('nav.references')}</Typography>
            <List sx={{maxWidth: 800}}>
                {referenceExportAndEditGuide.map((item, index) => (
                    <BulletPoint key={index} text={item} />
                ))}
            </List>
            <Typography variant="h6">{t('nav.cv')}</Typography>
            <List sx={{maxWidth: 800}}>
                {employeeCvGuide.map((item, index) => (
                    <BulletPoint key={index} text={item} />   
                ))}
            </List>
        </Box>
    )
};